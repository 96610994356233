<template>
  <div>
    <Head :title="title"/>

    <Banner/>
    <div class="flex flex-col min-h-screen bg-white">

      <!-- Navbar -->
      <div class="sticky">
        <Navigation
            :showing-navigation-dropdown="showingNavigationDropdown"
            @toggle-navigation-dropdown="toggleNavigationDropdown"
        />
      </div>

      <!-- Page Heading -->
      <header v-if="$slots.header" class="bg-green-100/10 w-full">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 max-h-auto">
          <slot name="header"/>
        </div>
      </header>

      <!--  Header: sticky -->
      <!--  Content: grow overflow-y -->
      <!--  Flex global : min-h-screen -->

      <!-- Page Content -->
      <div class="grow">
        <div id="layout-container" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 p-1 md:p-10">
          <main>
            <slot/>
          </main>
        </div>
      </div>
      <!-- Page Footer -->
      <Footer class="mt-auto"/>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {Head, router} from '@inertiajs/vue3';

import Footer from '@/Components/Client/Layouts/Footer.vue';
import Navigation from '@/Components/Client/Layouts/Navigation.vue';
import Banner from "@/Components/Banner.vue";

defineProps({
  title: String,
});

const showingNavigationDropdown = ref(false);

const toggleNavigationDropdown = () => {
  showingNavigationDropdown.value = !showingNavigationDropdown.value;
};

const switchToTeam = (team) => {
  router.put(route('current-team.update'), {
    team_id: team.id,
  }, {
    preserveState: false,
  });
};

const logout = () => {
  router.post(route('logout'));
};
</script>